:root {
  --color-telesalud: rgb(84, 61, 208);
}
.fadeIn {
  opacity: 1;
  transition: opacity 0.5s 0.5s;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s;
}

.border-tele {
  border: 1px solid var(--color-telesalud);
}

.bg-tele {
  background: var(--color-telesalud);
  color: white;
}
.text-tele {
  color: var(--color-telesalud);
  font-weight: 500;
}
.titulo-recurso {
  font-size: 1.2rem;
  color: var(--color-telesalud);
}
.titulo-recurso-ficha {
  font-size: 1.4rem !important;
  color: var(--color-telesalud);
  padding-top: 0.6em;
}

.descripcion-recurso {
  font-size: 0.9rem;
  text-align: justify;
}
.descripcion-recurso-ficha {
  font-size: 0.9rem !important;
  text-align: justify;
  font-weight: 500;
  justify-content: space-between;
}

.border-recurso-ficha{
  border: 0.01rem solid rgba(63, 43, 177, 0.144) !important;
  border-radius: 6px;
}


.img-recurso {
  width: 100px;
  min-height: 120px;
}

.recurso_sidebar {
  background-color: rgb(238, 238, 238);
  color: white;
  display: block;
  flex-direction: column;
  /* height: 100vh;    */
  width: 100%;
  height: auto;
}
.recursos {
  /* display: flex; */
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  padding: 0px 0px 10px 0px;
}
.recursos::-webkit-scrollbar {
  display: block !important;
  width: 20px !important;
  overflow-x: hidden !important;
}

.recursos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 15px rgb(177, 176, 176);
  border-radius: 10px;
  overflow-x: hidden !important;
}
.recursos::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--color-telesalud);
  overflow-x: hidden !important;
}
.recurso_entrada {
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
  color: black;
  /* display: flex; */
  margin: 8px 10px;
  overflow: hidden;
  width: 100%;
}

.recurso-body {
  width: 100%;
  height: 90px;
}

.recurso-title {
  font-size: 1rem;
  font-weight: bold;
}

.recurso-content {
  font-size: 0.8rem;
}

.pointer {
  cursor: pointer;
}

.footer-tele {
  color: var(--color-telesalud);
  font-size: 0.75rem;
}

.asignar_recursos {
  width: 80%;
}

.asign-block2 {
  position: relative;
  bottom: 0px;
  right: 0px;
  left: 20px;
  width: 100%;
}

.recurso_main {
  background-color: rgb(255, 255, 255);
  color: rgb(15, 15, 15);
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 0px;
  width: 100%;
}

.recursos_form {
  flex: 1 1 auto;
  overflow: scroll;
}

.iniciar-teleconsulta {
  padding: 3px !important;
}
.zoom {
  zoom: 80%;
}

.zoom_label {
  zoom: 85%;
  color: rgb(19, 19, 19);
}

.form-control-tele {
  display: block;
  width: 100%;
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.475rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--color-telesalud);
  background-color: #adb5bd1c;
  background-clip: padding-box;
  border: 0.01px solid #c4c4c456;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea.form-control-tele {
  min-height: 190px;
  font-weight: 500;
  resize: none;
}
select.form-control-tele > option {
  color: var(--color-telesalud);
  font-weight: bold;
}
.form-control-tele:focus {
  color: #363636;
  background-color: #fff;
  border-color: var(--color-telesalud);
  outline: 1;
  box-shadow: 0 0 0 0.2rem rgba(68, 0, 255, 0.137);
}
.hidden {
  display: none;
}

.btn-rounded {
  background-color: rgba(64, 43, 177, 1);
  color: white;
  border-radius: 20px;
  padding: 8px 34px;
  font-weight: 800;
  font-size: 13.5px;
}

.hover_actividades:hover {
  background-color: var(--color-telesalud);
  color: white !important;
  opacity: 1;

}
.sinhover_actividades {
  background-color: var(--color-telesalud);

}
.hover_actividades:hover > span {
  color: white;
  font-weight: 500;
}

span.wordBreak {
  word-break: break-word;
}

.slick-dots {
    position: absolute;
    bottom:-16px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: var(--color-telesalud) !important;
    border: none;
    -webkit-text-fill-color: black;
    outline: none;
}

.d-grid{
 display: inline-grid;
}

.not-allowed {
    cursor: not-allowed;
}

.screen-lateral{
    display: flex;
    height: 55vh;
    padding: 10px 10px 0px 10px;
    margin:  0px 0px 0px 0px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    z-index: 1;
}
.screen-content{
    display: flex;
    padding: 10px 10px 0px 0px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.screen-body{
    align-items: center;
    display: flex;
    transition: opacity .2s ease-in-out;
    z-index: 2;
    overflow-y: scroll !important;
}
.screen::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block !important;
    z-index: 999;
}
.screen::-webkit-scrollbar {
   width: 5px;
   background:var(--color-telesalud);
}
/* Track */
.screen::-webkit-scrollbar-track {
   background: #ededed;
}
/* Handle */
.screen::-webkit-scrollbar-thumb {
     background:var(--color-telesalud);
}

.hover-slider:hover
{
   background-color: #4354FE !important;
   color:#ffffff !important;
}

li.slick-active>div{
  background-color: #4354FE !important;
   color:#ffffff !important;
}

