.header-chat-bar{
    width:100%;
    display:flex;
}
    .colorWhite svg{
        color:white;
    }
    .header-chat-bar svg{
        color:white
    }
    .header-chat-bar h5{
        flex: 1 1 auto;
        min-width: 0;
        width: 100%;
        display: flex;
        font-size:1.2rem;
    }

    .chat-user-status{
        flex:1 1 auto;
        min-width: 0;
    }
    .chat-user-status strong,
    .chat-user-status small{
        display:block;
        width:100%;
    }

    .chat-user-status small{
        font-size: 13px;
        letter-spacing: 0.5px;
        margin-top:-10px;
    }

    .close-button{
        float: right;
    }