.wizzard-audio-container{
  display:block;
  padding:20px;
  margin:0px;
  border:1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.wizzard-audio-container p{
  font-family: 'Poppins', sans-serif; 
  font-size:14px;
  font-weight: 400;
  margin-bottom: 0;
}
.wizzard .btn-submit{
  border-radius: 3px;
  letter-spacing: 0.25px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif; 
  font-weight: 500;
}

.wizzard-audio-container h4{
  font-size:16px;
  color:black;
  font-weight: 600;
  font-family: 'Poppins', sans-serif; 
  word-break: break-word;
}

.wizzard-audio-container .btn-outline{
  background-color:white;
  border:1px solid #ff6666;
  color:#e74949;
  font-weight: 600;
}
.wizzard-audio-container .btn-outline:hover{
  background-color: #ff6666;
  color:white;
}