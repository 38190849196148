.messageNoConnected{
  background-color:#ddd;
  padding:6px 16px;
  display:block;
  margin:10px 20px;
  border-radius: 6px;
  font-size:13px;
  color:#333;
  font-weight: 400;
}
.messageNoConnected b{
  color:#4743C1;
  font-weight: 700;
}