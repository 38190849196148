
.messageText {
  color:black;
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 14px;
  word-wrap: break-word;
  margin-bottom:0px;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-bottom:15px;
}

.sentText {
  align-items: center;
  font-family: "Lato", "Arial", sans-serif;
  color: black;
  font-size: 13px;
  line-height: 14px;
  display: block;
  margin-bottom: 3px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
  display: block;
  text-align: left;
}

.justifyEnd {
   justify-content: flex-end;
   display: block;
   text-align: right;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: black;
}

.backgroundBlue {
  background: #28a745;
}

.backgroundLight {
  background: #F3F3F3;
}

.justifyEnd .sentText{
   text-align: right;
   
}
.justifyStart .sentText{
   text-align:left;
}
.messages{
   overflow-y: scroll;
   width: 100%;
}
.medicChatView{
   background-color:#f2f2f2;
}
.videoatencion{
   padding-top:10px;
}

.MessageSend{
  position: relative;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 0px 2px 0px rgb(0 0 0 / 12%);
}
  .MessageSend::before{
    content:'';
    height:8px;
    width:13px;
    display:block;
    position: absolute;
    left: -8px;
    top: 0px;
    border: 0 solid transparent;
    border-right-width: 0px;
    border-left-width: 14px;
    border-top: 17px solid white;
  }


.messageBox {
  background: white;
  border-radius: 20px;
  padding: 8px 12px;
  display: inline-block;
  max-width: 80%;
  border-radius: 4px;
}

  .messageBox small{
    opacity: 0.8;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display:block;
    clear: both;
    padding-top: 5px;
  }


.ownMessageSend{
  background: #4743C1;
  padding-right: 30px;
  position: relative;
}
  .ownMessageSend::before{
    content:'';
    height:8px;
    width:13px;
    display:block;
    position: absolute;
    right: -8px;
    top: 0px;
    border: 0 solid transparent;
    border-left-width: 0px;
    border-right-width: 14px;
    border-top: 17px solid #4743C1;
  }

  .ownMessageSend p{
    color:white;
  }
  .ownMessageSend small{
    color: white; 
  }


  