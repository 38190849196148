.develop-menu {
   display: none;
   position: absolute;
   background-color: whitesmoke;
   opacity: 0.5;
   top: 50px;
   right: 0px;
   padding: 10px;
   z-index: 1000;
}

.develop-menu .link-selected a {
   color: tomato !important;
}
