.resource-not-found{
  font-family: 'Poppins', sans-serif;
}

.resource-not-found .resource-not-found-container{
  padding: 2rem;
}

.resource-not-found .resource-not-found-container svg{
  font-size:6rem;
  color:#696A84;
  opacity: 0.4;
  margin-bottom: 1rem;
}

.resource-not-found .resource-not-found-container h1{
  display: block;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 120%;
  color: #4354FE;
  margin-bottom: 2rem;
}

.resource-not-found .resource-not-found-container h2{
  color: #696A84;
  line-height: 1.4rem;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}