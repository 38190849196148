

   .medicChatView .messages{
    font-size:13px;
 }
 .outerContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background-color: #1A1A1D;
 }
 /* 
 .container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   background: #FFFFFF;
   border-radius: 8px;
   height: 60%;
   width: 35%;
 } */
 
 @media (min-width: 320px) and (max-width: 480px) {
   .outerContainer {
     height: 100%;
   }
 
   .container {
     width: 100%;
     height: 100%;
   }
 }
 .chatPaciente button{
    background-color:#28a745;
 }
 
 .chatPacienteModal .MuiDialog-scrollPaper{
    align-items: flex-end;
 }  
 .chatPacienteModal .MuiDialog-paperFullScreen{
    height: 80%;
    overflow: hidden;
    max-width:480px;  
 }
 .chatPacienteModal .container{
    padding:0px;
    height:100%;
    width:100%;
    background-color:#F4F5F9;
 }
 .chatPacienteModal .infoBar h3{
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
 }
 .chatPacienteModal .medicNotConnected{
    background-color: red;
    color: white;
    padding: 6px 11px;
    display: block;
    margin: auto;
    font-size: 14.5px;
    font-weight: bold;
    width: 100%;
    text-align: center;
 }
 
 .chatPaciente .chatGlobe{
    border-radius: 3px;
    height: auto;
    padding: 10px;
    width: auto;
    z-index: 11;
 }
 .chatPaciente .chatGlobe svg{
    margin-right:5px;
 }
 .chatGlobe .newMessageIndicator{
    width: 18px;
    height: 18px;
    line-height:18px;
    font-size:14px;
    font-weight:bold;
    background-color: red;
    position: absolute;
    right: -7px;
    border-radius: 100%;
    top: -5px;
 }
 .chatGlobe .newMessagePopUp{
    position: absolute;
    left: -174px;
    background-color: white;
    border:1px solid #28a745;
    border-radius: 3px;
    padding: 4px 10px;
    width: 159px;
    color:black;
    min-height:40px;
    font-size: 14px;
    text-transform: lowercase;
    line-height: 16px;
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-wrap: anywhere;
 }
 .newMessageArrow{
    position: absolute;
    right: -18px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-left-color: #28a745;
    top: 11px;
 }
 .chatPacienteModal .form,
 .chatPacienteModal .sendButton{
    height:15%;
 }
 .chatPacienteModal .form input,
 .chatPacienteModal .form button{
    height:100%;
 }
 
 .connected-indicator{
    position:relative;
 }

  .list-mode .MuiListItemIcon-root{
   min-width: 45px;
  }
 
 .connected-indicator::after{
    position: absolute;
    top:0;
    left:0;
    margin-top:-3px;
    margin-left:-4px;
    width:12px;
    height:12px;
    border-radius: 50%;
    border:2px solid white;
    content:'';
 }
 
 .connected-true::after{
    background-color:#44b700;
 }
 
 .connected-false::after{
    background-color:red;
 }
 

/* CHAT CSS */

.chatWrapper {
    position: relative;
    width:100%;
}
.chatHeader {
    border-bottom: 1px solid #ddd;
    padding: 20px;
}
.chatHeader h2 {
    font-size: 16px;
    color: #3f4d71;
    line-height: 20px;
    display: inline-block;
}
.chatHeader .transacDetail {
    font-size: 11px;
    color: #86879c;
    display: block;
    margin: 2px 0px;
}
.chatHeader .transacDetail strong {
    color: #3f4d71;
    font-size: 13px;
    padding-left: 5px;
}
.chatParticipantes {
    padding: 20px;
    border-bottom: 1px solid #ddd;
}
.chatParticipantes h2 {
    font-size: 16px;
    color: #3f4d71;
    line-height: 20px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 14px;
}
.chatParticipantes .MuiListItem-root {
    padding: 0px;
}
.chatParticipantes .MuiAvatar-root {
    width: 35px;
    height: 35px;
}
.chatParticipantes .MuiListItemAvatar-root {
    min-width: 45px;
}
.chatParticipantes h4 {
    margin-bottom: 0px;
    font-size: 12px;
    color: black;
    font-weight: normal;
}
.chatParticipantes span {
    font-size: 11px;
    color: #666;
}
.chatParticipantes .MuiListItemSecondaryAction-root .MuiIconButton-root {
    padding: 8px;
}
.chatParticipantes .chatGroupName {
    margin-bottom: 15px;
}
.chatParticipantes .chatGroupName h4 {
    color: #42b883;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
}
.chatParticipantes .chatGroup {
    padding-bottom: 0px;
}
.chatParticipantes .chatGroup .color-success span {
    color: #42b883;
}
.chatParticipantes .delete span {
    color: #b2b7c6;
}
.no-border {
    border-bottom: 0px;
}
.chatList {
    max-height: 600px;
    height: 100%;
    overflow-y: scroll;
    background-color: #f0f4f8;
    position: relative;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
}
.chatList .chatListTitle {
    padding: 20px;
    background-color: white;
}
.chatList .chatListTitle h2 {
    font-size: 16px;
    color: #3f4d71;
    line-height: 20px;
    display: inline-block;
}
.chatList::-webkit-scrollbar, .participantesWrapper::-webkit-scrollbar {
    width: 0px;
    background: transparent;
   /* make scrollbar transparent */
}
.chatList::-webkit-scrollbar, .participantesWrapper::-webkit-scrollbar {
    width: 8px;
   /* Tamaño del scroll en vertical */
    height: 8px;
   /* Tamaño del scroll en horizontal */
    display: none;
   /* Ocultar scroll */
}
.chatList::-webkit-scrollbar-track, .participantesWrapper::-webkit-scrollbar-track {
    background-color: #e2e2e2;
    border-radius: 3px;
}
.chatList::-webkit-scrollbar, .participantesWrapper::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    display: block;
}
.chatList::-webkit-scrollbar-thumb, .participantesWrapper::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #47a7b7;
}
.chatItem {
    padding: 15px;
    border-top: 1px solid #ddd;
}
.chatItem h3 {
    font-size: 13px;
    line-height: 18px;
    padding: 0px;
    margin: 0;
    letter-spacing: 0;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 400;
}
.chatItem h4 {
    font-size: 13px;
    line-height: 15px;
    color: #333;
    font-weight: normal;
}
.chatItem .indicador {
    text-align: center;
    margin-top: 20px;
}
.chatItem .indicador .circulo {
    width: 10px;
    height: 10px;
    display: block;
    content: '';
    border-radius: 100%;
    background-color: #b03564;
    margin: auto;
    margin-bottom: 5px;
}
.chatItem .indicador .lastMessage {
    display: block;
    font-size: 12px;
    color: #999;
    line-height: 13px;
}
.chatItem .chatType {
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #999;
    vertical-align: middle;
    display: flex;
    align-items: center;
}
.chatItem .chatType svg {
    margin-right: 5px;
}
.chatItemActive {
    border-left: 5px solid transparent;
    background-color: white;
}
.chatItemDemanda .chatItemActive {
    border-left-color: #42b883;
}
.chatItemDemanda h3, .chatItemDemanda .chatType svg {
    color: #42b883;
}
.chatItemOferta .chatItemActive {
    border-left-color: #5e5f78;
}
.chatItemOferta h3, .chatItemOferta .chatType svg {
    color: #5e5f78;
}
.fullChatWrapper, .chatWrapper, .participantesWrapper {
    /*height: 600px;*/
}
.fullChatWrapper .chatHeader, .chatWrapper .chatHeader, .participantesWrapper .chatHeader {
    height: 18%;
}
.fullChatWrapper .chatBody, .chatWrapper .chatBody, .participantesWrapper .chatBody {
    height: 100%;
}
.fullChatWrapper .chatBody .MuiGrid-container, .chatWrapper .chatBody .MuiGrid-container, .participantesWrapper .chatBody .MuiGrid-container, .fullChatWrapper .chatBody .height-100, .chatWrapper .chatBody .height-100, .participantesWrapper .chatBody .height-100 {
    height: 100%;
}
.fullChatWrapper .chatBody .messagesWrapper, .chatWrapper .chatBody .messagesWrapper, .participantesWrapper .chatBody .messagesWrapper {
    min-height: 90%;
    overflow-y: scroll;
    background-color: #f2f2f2;
    max-height: 90%;
}
.fullChatWrapper .chatBody .chatControls, .chatWrapper .chatBody .chatControls, .participantesWrapper .chatBody .chatControls {
    padding: 20px;
    height: auto;
}
.transacDetail {
    font-size: 12px;
    color: #666;
    margin: 0px 14px;
    display: block;
}
.participantesWrapper {
    overflow-y: scroll;
}
.messageChat .messageAvatar {
    width: 30px;
    height: 30px;
}
.messageChat .messageBox .MuiCardContent-root {
    padding: 10px;
}
.messageChat .messageBox .messageTime {
    opacity: 0.6;
}
.messageChat .messageBox .fileDownloadChat {
    background-color: #f2f2f2;
    display: inline-block;
    padding: 4px 6px;
    border-radius: 5px;
    color: #999;
    border-bottom: 2px solid #ddd;
}
.messageChat .messageBox .fileDownloadChat svg {
    float: left;
}
.messageChat .messageBox .fileDownloadChat:hover {
    background-color: #47a7b7;
    color: white;
    border-bottom: 2px solid #47a7b7;
}
.chatLateral {
    position: absolute;
    right: 0px;
    top: 0px;
    max-width: 320px;
    background-color: white;
    box-shadow: -15px 0px 10px 0 rgba(0, 0, 0, 0.1);
}
.mensajeSalaChat img {
    height: 40vh;
}


/* CSS Chat anterior */
.medicChatView .messages{
  font-size:12px;
}
.outerContainer {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100vh;
 background-color: #1A1A1D;
}

@media (min-width: 320px) and (max-width: 480px) {
 .outerContainer {
   height: 100%;
 }

 .container {
   width: 100%;
   height: 100%;
 }
}
.chatPaciente{
   width:100%;
   height: 100%;
}
.chatPaciente button{
  background-color:#28a745;
}



.chatPacienteModal .MuiDialog-scrollPaper{
  align-items: flex-end;
}  
.chatPacienteModal .MuiDialog-paperFullScreen{
    max-width:480px;  
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.chatPacienteModal .infoBar h3{
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}
.chatPacienteModal .medicNotConnected{
  background-color: red;
  color: white;
  padding: 6px 11px;
  display: block;
  margin: auto;
  font-size: 14.5px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.chatPaciente .chatGlobe{
  border-radius: 3px;
  height: auto;
  padding: 10px;
  width: auto;
}
.chatPaciente .chatGlobe svg{
  margin-right:5px;
}
.chatPacienteModal .messages{
   padding: 20px 0px;
   height: 60vh;
}
.chatGlobe .newMessageIndicator{
  width: 18px;
  height: 18px;
  line-height:18px;
  font-size:14px;
  font-weight:bold;
  background-color: red;
  position: absolute;
  right: -7px;
  border-radius: 100%;
  top: -5px;
}
.chatGlobe .newMessagePopUp{
  position: absolute;
  left: -174px;
  background-color: white;
  border:1px solid #28a745;
  border-radius: 3px;
  padding: 4px 10px;
  width: 159px;
  color:black;
  min-height:40px;
  font-size: 14px;
  text-transform: lowercase;
  line-height: 16px;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-wrap: anywhere;
}

.newMessageText{
  position:absolute;
  top:-26px;
  color:red;
  width:102px;
  right:-8px;
  font-size:11px;
  border:1px solid red;
  font-weight: bold;
  border-radius: 3px;
  background-color:white;
  text-transform: capitalize
}
.newMessageText span{
  content: '';
  height: 8px;
  width: 13px;
  display: block;
  position: absolute;
  left: 40%;
  bottom: -11px;
  border: 0 solid transparent;
  border-right-width: 8px;
  border-left-width: 8px;
  border-top: 11px solid red;
}

.newMessageArrow{
  position: absolute;
  right: -18px;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-left-color: #28a745;
  top: 11px;
}
.chatPacienteModal .form,
.chatPacienteModal .sendButton{
  height:15%;
}
.chatPacienteModal .form input,
.chatPacienteModal .form button{
  height:100%;
}

.admin-submenu .btn {
   color: white;
   letter-spacing: 0px;
   font-size: 12px;
   letter-spacing: 0px;
   padding: 7px 16px;
   font-weight: 600;
   box-shadow: inherit;
   text-transform: uppercase;
}

.admin-submenu .btn svg {
   width: 20px;
   margin-left: 5px;
}



.chatPacienteModal .sectitle{
   background-color: #4354FE;
   color: white;
   padding-bottom: 5px;
   padding-top: 8px;
}
.chatLista{

}
.chatListaItem{
   border-bottom:1px solid #ddd;
   padding:10px;
}

.chatListaItem:hover{

}

.chatWrapper .message-bar{
   background-color: #f0f0f0;
   left: auto;
   right: 0;
   bottom: 0px;
   position: absolute;
   top:inherit;
   box-shadow: 0px 0px 32px 0px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.chatWrapper .message-bar input{
   background-color: white;
   border-radius: 20px;
}


.compact-mode{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
}

.list-mode{
    overflow-y:auto;
}

.lastmessage-recieved strong{
    color:black;
}


