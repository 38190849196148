* {
  --blue-perfil: #4D5DFE;
  --gray-perfil: #b8b8b8;
  --bg-perfil: #E7E9E8;
}


.bg-editar-perfil {
  background-color: var(--bg-perfil);
}

.text-title-perfil {
  color: var(--gray-perfil);
  font-weight: 500;
}

.text-perfil{
  color: var(--blue-perfil);
  font-weight: 600;
}

.section-mi-cuenta {
  /* background-color: white; */
  margin-right: 1rem !important;
}


.section-mi-cuenta .profile {
  line-height: 1.2;
  color: var(--blue-perfil);
  font-size: 1.1rem;
  font-weight: 700;
  display: block;

}

.item-section {
  background: white;
  padding: 0 !important;
}


.section-mi-cuenta .role {
  display: block;
  padding-top: 0.3rem;
  color: rgba(63, 62, 63, 0.719);
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: uppercase;
}


.MuiInputBase-root {
  font-size: 0.9rem !important;
}

.MuiSelect-icon {
  color: var(--blue-perfil) !important;
}

/* 
**Tamaño de los inputs material
*/

.MuiOutlinedInput-input {
  padding: 0.75rem 0.6rem !important;
  font-size: 0.8rem !important;
  min-height: 1em !important;
  border: rgb(255, 255, 255) 1px solid !important;
  background-color: rgb(249, 250, 255) !important;
}

.MuiChip-deleteIcon {
  fill: crimson !important;
}

.cerrar-sesion {}



.info {
  margin-right: 1rem;
}

.info-section {
  background-color: #ffffff;
  margin-bottom: 1rem;
}

.title-info-profesional {
  color: var(--blue-perfil) !important;
  font-size: 1.4rem;
  font-weight: 800;
  display: block;
}

.text-info-profesional {
  color: #818080;
  font-size: 1rem;
  font-weight: 400;
}

.text-label-profesional {
  color: #979797c9;
  font-size: 0.9rem;
  font-weight: 700;
}

.redblu-text-center {
  text-align: center !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.img-profesional{
  border-radius: 50% ;
  width: 120px ;
  height: 120px;
}


@media (max-width: 1279px){
  .oculta-responsivo{
    display:none;
  }
}