.chat-search svg{
  color:#999
}

.chat-search input{
  border-bottom:0;
}

.chat-search .MuiFormControl-root{
  background-color:#f2f2f2;
}
.chat-search .MuiFilledInput-root{
  border-bottom:1px solid #ccc;
}
.chat-search .MuiFilledInput-underline:before,
.chat-search .MuiFilledInput-underline:after{
  display:none;
}
.chat-search .MuiFilledInput-input{
  padding:12px 16px;
  font-size:14px;
}

.chat-search .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel){
  margin-top:0;
}

.chat-search input:focus{
  background-color:transparent;
}